<template>
  <div>
    <v-dialog v-model="show_dialog" persistent scrollable width="550">
      <v-card class="border-radius box-shadow">
        <v-card-title class="body-1 d-flex primary">
          <div class="white--text text-capitalize">
            Add Trainer
          </div>
          <v-spacer></v-spacer>
          <v-icon @click="hideDialog()" color="white">mdi-close-circle</v-icon>
        </v-card-title>
        <v-card-text class="pt-4">
          <ValidationObserver ref="observer">
            <v-form>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider ref="fullname"  name="Fullname" rules="required" v-slot="{ errors }">
                    <v-text-field 
                      label="Trainer Full Name" 
                      outlined 
                      dense 
                      autofocus
                      color="primary" 
                      hide-details="auto"
                      :error-messages="errors"
                      v-model="form.fullname">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider ref="email" name="Email" rules="required|email" v-slot="{ errors }">
                      <v-text-field 
                        label="Email" 
                        outlined 
                        dense 
                        color="primary" 
                        autocomplete="new-password"
                        hide-details="auto"
                        :error-messages="errors"
                        v-model="form.email">
                      </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider ref="phone_number" name="Phone Number" rules="required" v-slot="{ errors }">
                    <v-text-field 
                      label="Phone Number"
                      outlined 
                      dense 
                      color="primary" 
                      hide-details="auto" 
                      :error-messages="errors"
                      v-model="form.phone_number">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <p class="mb-0">Gender</p>
                  <v-radio-group row class="mt-2" hide-details="auto" :rules="rules.input" v-model="form.gender">
                    <v-radio color="primary" :value="1">
                      <template v-slot:label>
                        <div class="black--text">Male</div>
                      </template>
                    </v-radio>
                    <v-radio color="primary" :value="0">
                      <template v-slot:label>
                        <div class="black--text">Female</div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <p class="mb-0">Trainer Status</p>
                  <v-radio-group row class="mt-2" hide-details="auto" :rules="rules.input" v-model="form.status">
                    <v-radio color="primary" value="active">
                      <template v-slot:label>
                        <div class="black--text">Active</div>
                      </template>
                    </v-radio>
                    <v-radio color="primary" value="inactive">
                      <template v-slot:label>
                        <div class="black--text">Inactive</div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider ref="password" name="Password" vid="confirm" rules="required|min:4|max:20" v-slot="{ errors }">
                    <v-text-field 
                      label="Password" 
                      outlined 
                      dense 
                      color="primary" 
                      autocomplete="new-password" 
                      hide-details="auto"
                      :type="show_password_one ? 'text' : 'password'"
                      :append-icon="show_password_one ? 'mdi-eye-off' : 'mdi-eye'"
                      :error-messages="errors"
                       v-model="form.password"
                      @click:append="show_password_one = !show_password_one">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider name="Confirm Password" rules="required|min:4|max:20|confirmed:confirm" v-slot="{ errors }">
                    <v-text-field 
                      label="Confirm Password" 
                      outlined 
                      dense 
                      color="primary" 
                      autocomplete="off"
                      hide-details="auto" :type="show_password_two ? 'text' : 'password'"
                      :append-icon="show_password_two ? 'mdi-eye-off' : 'mdi-eye'"
                      :error-messages="errors"
                      v-model="form.confirm_password" 
                      @click:append="show_password_two = !show_password_two">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-3">
          <v-spacer></v-spacer>
          <v-btn color="grey lighten-2" class="text-capitalize mr-1" depressed width="120" height="40"
            :loading="btn_loading" @click="hideDialog()">
            Cancel
          </v-btn>
          <v-btn color="primary" class="white--text text-capitalize" depressed width="120" height="40"
            :loading="btn_loading" @click="saveUserTrainer()">
            Add Trainer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Snackbar ref="snackbar"></Snackbar>
  </div>
</template>

<script>
import { post, get } from "@/service/Axios";
import Snackbar from '@/components/Snackbar.vue';

export default {
  components: { Snackbar },
  data() {
    return {
      show_dialog: false,
      show_password_one: false,
      show_password_two: false,
      btn_loading: false,
      rules: {
        input: [v => !!v || 'This field is required'],
        email: [v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'],
        password: [
          v => !!v || 'This field is required',
          v => (v && v.length >= 7) || "Password can't less than 7 characters",
        ],
      },
      form: {
        fullname: "",
        email: "",
        phone_number: "",
        gender: 1,
        status: "active",
        password: "",
        confirm_password: "",
      },
      error_message: {},
      check_confirm_password: '',
      roles: {
        list: []
      }
    }
  },
  props: {
    company_id: String,
  },
  async mounted() {
    this.roles = await this.getRoles()
  },
  computed: {
    roleSelect() {
      return this.roles.list.map((item) => {
        return {
          label: item.name,
          value: item.id
        }
      })
    }
  },
  methods: {
    async getRoles() {
      const { data } = await get('users/v1/superuser/role/list', {
        query: {
          company_id: this.company_id,
        }
      })
      return data?.data
    },
    showDialog() {
      this.show_dialog = true
    },
    hideDialog() {
      this.show_dialog = false
      this.btn_loading = false
      this.$refs.observer.reset()
      this.$refs.observer.resetValidation()
      this.error_message = {}
    },
    async saveUserTrainer() {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.btn_loading = true
        await post(`trainer/v1/create`, {
          data: {
            company_id: this.company_id,
            fullname: this.form.fullname,
            email: this.form.email,
            phone_number: this.form.phone_number,
            gender: this.form.gender,
            status: this.form.status,
            password: this.form.password,
            confirm_password: this.form.confirm_password,
          }
        }).then((response) => {
          let res = response.data
          if (res.status == 200) {
            this.hideDialog()
            this.$refs.snackbar.open("#4CAF50", `Add Trainer Successfully`);
            this.$emit('action')
          } else {
            this.hideDialog()
            this.$refs.snackbar.open("#e53935", `Add Trainer Failed`);
          }
        }).catch((error) => {
          console.log(error);
          if (error.response) {
            this.btn_loading = false;
            if(Object.keys(error.response.data.validation).length > 0) {
              Object.keys(error.response.data.validation).forEach(item => {
                this.$refs[item].applyResult({
                  errors: [error.response.data.validation[item]],
                  valid: false,
                  failedRules: {},
                });
              })
            }else {
              this.$refs.snackbar.open("#e53935", error.response.data.message);
            }
          }
          // this.error_message = this.$store.state.ContentValidation
          this.btn_loading = false
        })
      }
    },
  },
};
</script>
