<template>
  <div>
    <v-dialog v-model="show_dialog" persistent scrollable width="500">
      <v-card class="border-radius box-shadow">
        <v-card-title class="body-1 d-flex primary">
          <div class="white--text">
            Change Password Trainer <span class="text-uppercase font-weight-bold">({{ fullname }})</span>
          </div>
          <v-spacer></v-spacer>
          <v-icon @click="hideDialog()" color="white">mdi-close-circle</v-icon>
        </v-card-title>
        <v-card-text class="pt-4">
          <ValidationObserver ref="observer">
            <v-form>
              <v-row>
                <!-- <v-col cols="12" class="pb-1">
                  <div>Ganti Password Trainer <b class="text-uppercase">{{ fullname }}</b></div>
                </v-col> -->
                <v-col cols="12">
                  <ValidationProvider name="Current Password" rules="required|min:4|max:20" v-slot="{ errors }">
                    <v-text-field
                      label="Current Password"
                      outlined
                      autofocus
                      dense
                      color="primary"
                      autocomplete="off"
                      tabindex="1"
                      hide-details="auto"
                      :type="show_password_one ? 'text' : 'password'"
                      :append-icon="show_password_one ? 'mdi-eye-off' : 'mdi-eye'"
                      :error-messages="errors"
                      v-model="form.current_password"
                      @click:append="show_password_one = !show_password_one">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider name="Password" vid="confirmation" rules="required|min:6|max:20" v-slot="{ errors }">
                    <v-text-field
                      label="New Password"
                      outlined
                      dense
                      color="primary"
                      autocomplete="off"
                      tabindex="2"
                      hide-details="auto"
                      :type="show_password_two ? 'text' : 'password'"
                      :append-icon="show_password_two ? 'mdi-eye-off' : 'mdi-eye'"
                      :error-messages="errors"
                      v-model="form.password"
                      @click:append="show_password_two = !show_password_two">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider name="Confirm Password" rules="required|min:6|max:20|confirmed:confirmation" v-slot="{ errors }">
                    <v-text-field
                      label="Confirm New Password"
                      outlined
                      dense
                      color="primary"
                      autocomplete="off"
                      tabindex="3"
                      hide-details="auto"
                      :type="show_password_three ? 'text' : 'password'"
                      :append-icon="show_password_three ? 'mdi-eye-off' : 'mdi-eye'"
                      :error-messages="errors"
                      @click:append="show_password_three = !show_password_three"
                      v-model="form.confirm_password"
                      required>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions class="py-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-2"
            class="text-capitalize mr-1"
            depressed
            :loading="btn_loading"
            @click="hideDialog">
            Cancel
          </v-btn>
          <v-btn 
            color="primary"
            class="white--text text-capitalize"
            depressed
            :loading="btn_loading"
            @click="changePassword">
            Change Password
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Snackbar ref="snackbar"/>
  </div>
</template>

<script>
import { get, put } from '@/service/Axios'
import Snackbar from '@/components/Snackbar.vue'

export default {
  components: { Snackbar },
	data () {
		return {
			show_dialog: false,
      show_password_one: false,
      show_password_two: false,
      show_password_three: false,
			btn_loading: false,
      rules: {
        password: [
          v => !!v || 'This field is required',
				  v => ( v && v.length >= 7 ) || "Password can't less than 7 characters",
				],
			},
      details: {},
      user_id: '',
      fullname: '',
      form: {
        current_password: '',
        password: '',
        confirm_password: '',
      },
      process: {
        run: false
      },
      error_message: {},
      check_confirm_password: ''
		}
	},
	computed: {
	},
	methods: {
    async showDialog(name, id) {
      await this.getDetail(id)
      this.user_id = id
      this.fullname = name
      this.show_dialog = true
    },
		hideDialog() {
			this.show_dialog = false
			this.btn_loading = false
      this.$refs.formChangePassword.reset()
      this.$refs.formChangePassword.resetValidation()
      this.error_message = {}
		},
    checkConfirmPassword() {
      if (this.form.confirm_password != this.form.password) {
        this.check_confirm_password = "Confirm New Password different with New Password"
      } else {
        this.check_confirm_password = ""
      }
    },
    async getDetail(id) {
      let _self = this;
      _self.process.run = true;

      await get(`trainer/v1/detail/${id}`).then(
        (response) => {
          let res = response.data;
          this.details = res.data;
          this.process.run = false;
          this.$store.state.process.run = false;
        }
      );
    },
    async changePassword() {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.btn_loading = true
        let data = {
          fullname: this.details.fullname,
          email: this.details.email,
          phone_number: this.details.phone_number,
          gender: this.details.gender.value,
          current_password: this.form.current_password,
          password: this.form.password,
          confirmation_password: this.form.confirm_password,
        }
        await put(`trainer/v1/update/${this.user_id}`, {
          data: data
        }).then((response) => {
          let res = response.data
          if (res.status == 200) {
            this.hideDialog()
            this.$refs.snackbar.open("#4CAF50", `Change Password Successfully`);
          } else {
            this.hideDialog()
            this.$refs.snackbar.open("#e53935", `Change Password Failed`);
          }
        }).catch(() => {
          this.error_message = this.$store.state.ContentValidation
          this.btn_loading = false
        })
      }
    },
	}
};
</script>